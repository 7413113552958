
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&display=swap');

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
}

#main {
display: flex;
  flex-direction: column;
  margin: 0 auto;

  justify-content: center;

  /* padding: 0 1rem ; */


}

section {
  position: relative;
}

section.dark {
  min-height: 100vh;
  min-width: 320px;
  color: white;
  background: rgba(0, 0, 0, 0.25);


}

section.light {
  color: black;
  background: rgba(255, 255, 255, 0.35);
  padding: 8rem 0;
}

section img.background {
  width: 100vmax;
  height: 100%;
  min-height: 100vh;
  min-width: 320px;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
  object-fit: cover;
}
.whiteBackground{
  border-radius: 15px;
  padding: 20px;
}
.background{
  width: 100vmax;
  height: 100%;
  min-height: 100vh;
  min-width: 320px;
  border-radius: 25px;
  padding: 20px;
}
h1 {
  font-weight: 300;
  font-family: "League Spartan", sans-serif;
  font-size: 5.5rem;
  margin: 0;
}

h2 {
  font-weight: 425;
  font-family: "League Spartan", sans-serif;
  font-size: 3rem;
  margin: 0;
  text-align: center;
}

h3 {
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

p {
  font-size: 17px;
  line-height: 1.5;
  /* margin-bottom: 0; */
  font-weight: 300;
}

p.large {
  font-size: 24px;
}

p.small {
  font-size: 15px;
}
@media (max-width: 550px) {
  p { font-size: 1em; }
}

@media (min-width: 390px) {
  p { font-size: 1.25rem; }
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 5;

}

li::marker {
  color: #4e567e;
}

a {
  text-decoration: 3rem;
  color: black;
}

a:hover {
  color: #4e567e;
}
.portfolio{
  margin-top: 1rem;
}
.portframe{
  display: block;  
  margin-left: auto;  
  margin-right: auto;  
  width: 300px;
  height: 300px;
  /* position: relative; */
  overflow: hidden;
  border-radius: 50%;
justify-content: center;
border: 3px solid #4b5563;
}
.portpic{
border-radius: 100;
  display: flex;
  /* margin: 0 auto; */
/* margin-left: 31%; */
  height: auto;
  max-width:100% ;
  width: auto;
  justify-content: center;
}
.resButton{
  border-radius: 15px;
  padding: 3px;
  box-shadow: 1px 2px #888888;

}
.dres{
  font-size: 1.5rem;
font-weight: lighter;
color: black;
}
.dres:hover{
 
  color: gray;
  border: 2px transparent;
  
 
}
.skillslist {
  margin: 20px auto;
  justify-content: center;


}
.SkillContainer{
  display: flex;
  align-items: center;
}
.devicon-devicon-plain{

  width: 30px;
  height: 30px;
}
.container {
  margin: 20px auto;
  display: grid;
  justify-content: center;
  grid-template-columns: 300px 300px;
  grid-gap: 60px;
  padding:0 2rem;
}

.container .box {
  border-radius: 10px;
  padding: 24px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 3px solid rgb(204, 204, 204);
  text-align: center;
  box-shadow: 2px 5px #888888;
}

img.socialIcon {
  width: 30px;
  height: 30px;
}
@media only screen and (max-width:550px){
  .navBar{
gap: 2rem ;
padding: .5rem;
margin: 0 auto;
position: relative;
left: -15px;
  }
  .arrowIcon{

position: absolute;
left: 40% !important;


  }
  .container{
    grid-template-columns: 1fr;
   
  }
}